import React from "react";
import styled from "styled-components";

const EscapeIcon = styled.svg`
  &:hover {
    & path {
      stroke: #cc0000;
    }
  }

  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
  }
`;

export const CloseMenu = () => (
  <EscapeIcon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 17L9.05369 9L1 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 1L8.94631 9L17 17"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </EscapeIcon>
);
