import React, { useState } from "react";
import styled from "styled-components";
import Scrollspy from "react-scrollspy";
import { use100vh } from "react-div-100vh";
import { useStaticQuery, graphql } from "gatsby";

// Components
import { Info } from "../info/info";
import { CloseMenu } from "../icons/icons";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 25px;

  color: #000;
  // mix-blend-mode: difference;

  // & .left {
  //   & span {
  //     position: absolute;
  //     left: 25px;
  //     mix-blend-mode: difference;

  //     &.black {
  //       color: #000;
  //       z-index: 1;
  //     }

  //     &.white {
  //       color: #fff;
  //       z-index: 2;
  //     }
  //   }
  // }

  // & .right {
  //   & span {
  //     position: absolute;
  //     right: 25px;
  //     mix-blend-mode: difference;

  //     &.black {
  //       color: #000;
  //       z-index: 1;
  //     }

  //     &.white {
  //       color: #fff;
  //       z-index: 2;
  //     }
  //   }
  // }

  & nav {
    & ol {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin: 0;
      padding: 0;

      & li {
        color: #000;

        & button {
          color: #000;
        }
      }

      & li:hover {
        color: #cc0000;

        & > button {
          color: #cc0000;
        }
      }

      & li.info {
        &:hover {
          color: #000;
        }
      }
    }
  }

  z-index: 200;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  cursor: pointer;

  & li {
    flex: 100%;
    display: ${props => props.displayListItems};

    &.is-current {
      display: block;
    }

    &:last-of-type {
      display: none;

      &.is-current {
        display: ${props => props.displayLastItem};
      }
    }
  }
`;

export const Header = ({ location }) => {
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const height = use100vh();

  const data = useStaticQuery(graphql`
    {
      prismicProjects {
        data {
          website_heading {
            text
          }
        }
      }
    }
  `);

  return (
    <>
      <HeaderWrapper>
        <nav>
          <ol>
            <li className="left">
              {isInfoVisible ? (
                <ol>
                  <li className="info">
                    <span className="black">Info</span>
                  </li>
                </ol>
              ) : (
                <DropdownMenu
                  onMouseEnter={() => setIsMenuVisible(true)}
                  onMouseLeave={() => setIsMenuVisible(false)}
                  displayListItems={isMenuVisible ? `block` : `none`}
                  displayLastItem={isMenuVisible ? `none` : `block`}
                >
                  <Scrollspy
                    items={[
                      "site-title",
                      "projects",
                      "products",
                      "bottom-site-title",
                    ]}
                    currentClassName="is-current"
                  >
                    <li className="site-title">
                      {data.prismicProjects.data.website_heading.text}
                    </li>
                    <li className="projects">
                      <button
                        onClick={() =>
                          document.querySelector("#projects").scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          })
                        }
                      >
                        Projects
                      </button>
                    </li>
                    <li className="products">
                      <button
                        onClick={() =>
                          document.querySelector("#products").scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          })
                        }
                      >
                        For Sale
                      </button>
                    </li>
                    <li className="bottom-site-title">
                      {data.prismicProjects.data.website_heading.text}
                    </li>
                  </Scrollspy>
                </DropdownMenu>
              )}
            </li>

            <li className="right">
              <button onClick={() => setIsInfoVisible(!isInfoVisible)}>
                {isInfoVisible ? <CloseMenu /> : <>Info</>}
              </button>
            </li>
          </ol>
        </nav>
      </HeaderWrapper>

      <Info
        isInfoVisible={isInfoVisible}
        setIsInfoVisible={setIsInfoVisible}
        height={height}
      />
    </>
  );
};
