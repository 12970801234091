import React from "react";
import styled from "styled-components";

// Components
import { Header } from "./header";

const Main = styled.main`
  flex: 1;

  position: relative;
  z-index: 1;

  background: #fff;
`;

export const App = ({ location, children }) => (
  <>
    <Header location={location} />
    <Main>{children}</Main>
  </>
);
