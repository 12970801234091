import React from "react";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Layout = ({ children }) => (
  <>
    <Normalize />
    <GlobalStyles />
    <DefaultSEO />
    <App children={children} />
  </>
);

export default Layout;
