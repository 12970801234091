import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html,
html.wf-loading {
  main{
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  main {
    opacity: 1;
  }
}


body {
  font-family: "DINRotring", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 24px;
  line-height: 130%;

  color: #000;
  margin: 0;

  @media(max-width: 900px){
    font-size: 20px;
    line-height: 24px;
  }

  @media(max-width: 700px){
    font-size: 16px;
    line-height: 20px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "DINRotring", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;

  font-size: 24px;
  line-height: 130%;
  // letter-spacing: 0.15px;

  @media(max-width: 900px){
    font-size: 20px;
    line-height: 24px;
  }

  @media(max-width: 700px){
    font-size: 16px;
    line-height: 20px;
  }
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;

    border: 0;
    padding: 0;
    margin: 0;

    cursor: pointer;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

// ::-webkit-input-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// ::-moz-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-ms-input-placeholder{
//       color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-moz-placeholder {
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

//   }
`;

export default GlobalStyle;
